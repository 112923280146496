<template>
  <!-- <div > -->
  <div class="framePage-scroll">
    <div class="ovy-a">
      <!-- <h3 style="padding-bottom: 20px">基本信息</h3> -->
      <el-form
        ref="Form"
        :model="Form"
        :rules="rules"
        label-width="120px"
        class
      >
        <el-form-item label="企业名称：" class="form-item">
          {{companyAddList[0].compName}}
        </el-form-item>
        <el-form-item label="企业负责人："  class="form-item">
         {{Form.chargePerson}}
        </el-form-item>
        <el-form-item label="联系方式：" class="form-item">
         {{Form.mobile}}
        </el-form-item>
        <el-form-item label="签约金额："  class="form-item">
         ￥{{Form.amountMoney}}
        </el-form-item>
        <el-form-item label="内容有效期至："  class="form-item">
         {{Form.endDate}}
        </el-form-item>
          </el-form>
        </div>
      </div>
</template>

<script>
export default {
  name: "basicInformation",
  components: {},
  props:{
    //订单Id
     orderId: {
        type: String,
        default: '',
      },
  },
  data() {
    return {
      Form: {}, //基本信息数据
      companyAddList:[], //企业名称数据
    };
  },
  created() {
    this.getOrderInfo(this.orderId);
  },
  computed: {},
  methods: {
  // 编辑订单回显数据
    getOrderInfo(orderId) {
      this.$post("/biz/et/order/getCompOrderSensitive", { orderId }).then((res) => {
        if (res.status == "0") {
          this.getCompany(res.data.compId);
           this.Form = {
            ...res.data,
             endDate:res.data.endDate.replaceAll('/','-'),
          };
        }
      });
    },
    // 根据compId查询单位名称
    getCompany(compId) {
      this.$post("/sys/company/id", { compId }).then((res) => {
        this.companyAddList = [res.data];
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>
