<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">订单中心</a>
          <i>></i>
          <a href="javascript:;" @click="goBack()">企业内训订单</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">详情</a>
        </span>
      </div>
      <div class="framePage-body framePage-tabs">
        <el-tabs
        class="tabsFirst"
          v-model="activeName"
          :before-leave="beforeTabLeave"
          @tab-click="tabChange"
        >
          <el-tab-pane label="基本信息" name="first">
            <basicInformation ref="first" :orderId='orderId'/>
          </el-tab-pane>
          <el-tab-pane label="分发内容" name="second">
            <el-tabs
              v-model="distributeActiveName"
              :before-leave="beforeTabLeave"
            >
              <el-tab-pane label="课程内容" name="first">
                <distributeCourseList ref="distributeCourseList" :stu="false" :orderId='orderId'/>
              </el-tab-pane>
              <el-tab-pane label="试卷内容" name="second">
                <distributeTestPaper ref="distributeTestPaper" :stu="false" :orderId='orderId'/>
              </el-tab-pane>
            </el-tabs>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import List from "@/mixins/List";
import basicInformation from "@/views/CustomPrograms/basicInformation.vue"; //基本信息
import distributeCourseList from "@/views/CustomPrograms/distributeCourseList.vue"; //课程内容
import distributeTestPaper from "@/views/CustomPrograms/distributeTestPaper.vue"; //试卷内容

import { mapGetters } from "vuex";
export default {
  name: "CustomPrograms/enterpriseInternalTrainingOrderView",
  components: {
    basicInformation,
    distributeCourseList,
    distributeTestPaper
  },
  mixins: [List],
  data() {
    return {
      activeName: "first", //基本信息分发名称
      distributeActiveName: "first", //分发内容tab名称
      orderId:this.$route.query.orderId //订单Id
    };
  },
  mounted() {},
  watch: {},
  created() {},
  computed: {},
  methods: {
   //返回
    goBack() {
        this.$router.push({
          path: "/web/CustomPrograms/enterpriseInternalTrainingOrder",
          query: {
            refresh: true,
          },
        });
    },
  },
};
</script>
<style lang="less" scoped>
.framePage-tabs {
  flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  background-color: #fff;
  padding: 1rem 0.675rem;
  box-sizing: border-box;
   .tabsFirst {
    width: 100%;
  }
}
</style>
